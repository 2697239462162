import { render, staticRenderFns } from "./area52.vue?vue&type=template&id=48bc8bb4"
import script from "./area52.vue?vue&type=script&lang=js"
export * from "./area52.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../.nvm/versions/node/v12.13.0/lib/node_modules/@sa-platform/sa-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports