
<template>

</template>

<script>


import SuperAppComponentCore from '@sa-platform/sa-p-component-core'

import state from "./store.js";

import axios from 'axios';

import * as push from "./lib/push.js";

export default {

   extends: SuperAppComponentCore,

  /* Note: About Props:  since it is agains to the best pratices to manipulate Vue's properties (input parameters), we will create helper variables for those props and add it to data scope. */

  props: {

  },
  computed:{
    GTconfig: {
      get() {
        return this.$store.getters["SATemplate/getConfig"];
      }
    }
  },
  async created() {

    

    if(!this.$store.getters.hasOwnProperty("SATemplate/getConfig"))
    {
      this.$store.registerModule("SATemplate", state);

      await this.$store.dispatch('SATemplate/init',{});

      //console.log("SATemplate ready");

      console.log("SATemplate General Core Ready");


      this.pushn_status = false;
      this.pushn_basic_topics = "";


      if(this.GTconfig.options)
      {
        this.pushn_status = this.GTconfig.options.pushn_status || false;
        this.pushn_basic_topics = this.GTconfig.options.pushn_basic_topics || "";
      }

     // console.log("GT configs",this.GTconfig,this.pushn_status,this.pushn_basic_topics);

      //setup pushes
      if(this.pushn_status)
      {
        console.log("Setup push");
        this.setupPushes();
        this.listen("user-session-init",this.initPushNotifications);
        this.listen("user-session-created",this.initPushNotifications);
        this.listen("user-session-expired",this.expirePush);

   /*      const user = window.SAPlatform.user.current();
        if(push && user)
        {
          this.initPushNotifications();
        }
 */

      }

    }




  },
  data() {
    return {

    }
  },
  methods:{

    ipCheck()
    {

        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        const ratio = window.devicePixelRatio || 1
        const screen = {
          width: window.screen.width * ratio,
          height: window.screen.height * ratio,
        }

        const iosX = screen.width == 1125 && screen.height === 2436
        const iosXr = screen.width == 828 && screen.height === 1792
        const iosXMax = screen.width == 1242 && screen.height === 2688

        if (iOS) {
          if (iosX || iosXr || iosXMax) {
              
              var html = document.getElementsByTagName('html');
              if(html.length>0)
              {
                html[0].classList.add("iphone-x");
              };
          }
        }
   
    },
    inAppBrowserInit()
    {

      //Listening log in
      this.listen("openbrowser-system",data=>{

          if(window.cordova)
          {

            if(cordova.InAppBrowser)
            {
              console.log("openBrowser System");
              let iab = cordova.InAppBrowser;
              iab.open(data.detail.value, '_system');
            }

          }
          else
          {
            let url = data.detail.value;
            url = url.replace("&nbsp;","");
            window.open(url, '_blank');
          }

      });


      this.listen("openbrowser-self",data=>{

          if(window.cordova)
          {

            if(cordova.InAppBrowser)
            {
              console.log("openBrowser Self");
              let iab = cordova.InAppBrowser;
              iab.open(data.detail.value, '_self');
            }

          }
          else
          {
            let url = data.detail.value;
            url = url.replace("&nbsp;","");
            window.open(url, '_blank');
          }

      });


      this.listen("openbrowser-self-cache",data=>{

          if(window.cordova)
          {
            if(cordova.InAppBrowser)
            {
              console.log("openBrowser Self");
              let iab = cordova.InAppBrowser;
              iab.open(data.detail.value, '_blank','presentationstyle=fullscreen,toolbarposition=top,hidenavigationbuttons=no,clearcache=no,clearsessioncache=no,cleardata=no');
            }
          }
          else
          {
            let url = data.detail.value;
            url = url.replace("&nbsp;","");
            window.open(url, '_blank');
          }

    
      });

    },
    deviceReady() 
    {
      return new Promise(resolve => {
        document.addEventListener("deviceready", resolve, {once: true});
      });
    },
    async registerPush() 
    {

      console.log("registerPush");
      if(push)
      {
        await this.deviceReady();
        await push.registerDevice();
         console.log("registerPush done");

      }
      return false;
    },
    async expirePush() 
    {
      console.log("expirePush");
      if(push)
      {
        await this.deviceReady();
        await push.removeDevice();
      }
      return false;
    },
    async setupPushes() 
    {
      console.log("setupPushes");
      if(push)
      {
        await this.deviceReady();
        if (!push.isAvailable()) return;
        await push.requestPermission();
         console.log("setupPushes done");

      }
      return false;
    },
    async initPushNotifications()
    {

      console.log("initPushNotifications");

        const user = window.SAPlatform.user.current();

        if(push && user)
        {
          console.log("Register pushes start");
          await this.registerPush();
          push.subscribeTopic("general");
          if(this.pushn_basic_topics.length > 1)
          {
            let topics = this.pushn_basic_topics.split(",");
            for( let topic of topics)
            {
                push.subscribeTopic(topic);
            }

          }
          console.log("Register pushes done");
        }
    },
    addStyleSheet(name,url)
    {
     //link doesnt exists that this  doesnt exists yet
      let sastyle = document.getElementById(name);
      if(sastyle){ sastyle.remove(); }

      let div = document.createElement('div');
      div.innerHTML = "<link id='"+name+"' rel='stylesheet' href='"+url+"'>";
      document.head.appendChild(div);
    }


  },
  mounted()
  {
   // console.log("Location:"+window.location);
    if(!window.Editor)
    {

      //console.log("Mounted General Template CORE && Adding general template styles: "+window.SAPlatform.server.url);

      //Adding stylesheet
      this.addStyleSheet('sa-styles',window.SAPlatform.server.url+"/wp-json/template/production/style/all/");

      //device check
      this.ipCheck();

      this.inAppBrowserInit();


    }

    setTimeout(()=>{

      if(window.Editor){

          let sastyle = document.getElementById("sa-styles");
          if(sastyle){ sastyle.remove(); }

          if(jQuery)
          {

            if(jQuery("style").eq(4))
            {
                let str = jQuery("style").eq(4).html();

                //making sure we are not removing wrong css
                var n1 = str.search("editor");
                var n2 = str.search("Framework7");

                if(n1 == -1 && n2 == -1)
                {
                  jQuery("style").eq(4).html("");
                }
                else
                {
                  console.log("CANNOT REMOVE APP STYLES 1");
                }

            }
            else
            {
              console.log("CANNOT REMOVE APP STYLES 2");
            }

          }
        }
    },3000);
  }
}


</script>

<style>

</style>
