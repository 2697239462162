<template>
  <f7-navbar :color-theme="headerColorTheme" class="nav-header" v-bind="$attrs" :style="headerStyle ">


    <f7-nav-left>
      <f7-link v-if="headerNavLeft.back"  @click="click(headerNavLeft);back(headerNavLeft);">
        <sac-c-sageneral-icon v-if="headerNavLeft.icon.length > 0" :icon="headerNavLeft.icon"></sac-c-sageneral-icon>
        <span>{{ headerNavLeft.label }} </span>
      </f7-link>

      <!-- link -->
      <f7-link v-if="headerNavLeft.type == 'link'" :href="'/' + headerNavLeft.link + '/'" @click="click(headerNavLeft)">
        <sac-c-sageneral-icon v-if="headerNavLeft.icon.length > 0" :icon="headerNavLeft.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavLeft.label }} </span>
      </f7-link>

      <!-- sidebar right -->
      <f7-link v-if="headerNavLeft.type == 'sidebar-right'" panel-open="right" @click="click(headerNavLeft)">
        <sac-c-sageneral-icon v-if="headerNavLeft.icon.length > 0" :icon="headerNavLeft.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavLeft.label }} </span>
      </f7-link>

      <!-- sidebar left -->
      <f7-link v-if="headerNavLeft.type == 'sidebar-left'" panel-open="left" @click="click(headerNavLeft)">
        <sac-c-sageneral-icon v-if="headerNavLeft.icon.length > 0" :icon="headerNavLeft.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavLeft.label }}</span>

      </f7-link>

      <!-- popup close-->
      <f7-link v-if="headerNavLeft.type == 'popup-close'" popup-close  @click="click(headerNavLeft)">
        <sac-c-sageneral-icon v-if="headerNavLeft.icon.length > 0" :icon="headerNavLeft.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavLeft.label }}</span>

      </f7-link>




    </f7-nav-left>

    <f7-nav-title v-if="!logo">{{ title || headerTitle }}</f7-nav-title>

    <f7-nav-title v-if="logo"><img :style="{ width: logo_width }" :src="logo"/></f7-nav-title>

    <f7-nav-right>
      <!-- link -->
      <f7-link v-if="headerNavRight.type == 'link'" :href="'/' + headerNavRight.link + '/'" @click="click(headerNavRight)">
        <sac-c-sageneral-icon v-if="headerNavRight.icon.length > 0" :icon="headerNavRight.icon"></sac-c-sageneral-icon>

        {{ headerNavRight.label }}
      </f7-link>

      <!-- sidebar right -->
      <f7-link v-if="headerNavRight.type == 'sidebar-right'" panel-open="right"  @click="click(headerNavRight)">
        <sac-c-sageneral-icon v-if="headerNavRight.icon.length > 0" :icon="headerNavRight.icon"></sac-c-sageneral-icon>

        {{ headerNavRight.label }}
      </f7-link>

      <!-- sidebar left -->
      <f7-link v-if="headerNavRight.type == 'sidebar-left'" panel-open="left"  @click="click(headerNavRight)">
        <sac-c-sageneral-icon v-if="headerNavRight.icon.length > 0" :icon="headerNavRight.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavRight.label }}</span>
      </f7-link>

      <!-- popup close -->
      <f7-link v-if="headerNavRight.type == 'popup-close'" popup-close  @click="click(headerNavRight)">
        <sac-c-sageneral-icon v-if="headerNavRight.icon.length > 0" :icon="headerNavRight.icon"></sac-c-sageneral-icon>

        <span>{{ headerNavRight.label }}</span>
      </f7-link>

      
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import SuperAppComponentCore from "@sa-platform/sa-p-component-core";
import SuperAppTemplateCore from "@sa-platform/sa-p-template-core";

import axios from "axios";

import { store } from "@/store/store.js";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

//import SAGeneralCore from '../sac-c-sageneral-core/sac-c-sageneral-core.vue'

export default {
  mixins: [SuperAppTemplateCore],
  computed: {
    /* All styles */
    templateStyle: {
      get() {
        return this.$store.getters["SATemplate/getTemplateConfig"](this.$data.template);
      }
    },

    /* header */
    headerColorTheme: {
      get() {
        //console.log("header color theme",this.$store.getters["SATemplate/getHeaderColorTheme"]);
        return this.$store.getters["SATemplate/getHeaderColorTheme"];
      }
    },
    headerStyle() {
      return this.headerStyles(this.$data.postId);
    }

  },


  props: {

    title:String

  },
  data() {
    return {
      headerTitle: "",
      postId:0,
      template:"",
      logo_width:"auto",
      logo:false,
      header_status:"on",
      headerNavRight:{type:false,link:"",label:"",icon:"",img:"",back:false},
      headerNavLeft:{type:false,link:"",label:"",icon:"",img:"",back:false}

    };
  },
  methods: {

    click: function(item)
    {


      const event_name = item["event_name"] || "";
     
      if(event_name.length > 1)
      {
        const event_value = item["event_value"] || "";

        const pm_event= new CustomEvent(event_name, {detail: event_value});
        window.dispatchEvent(pm_event);
      }

    },
    back(item)
    {

      if(this.post) {window.SAPlatform.nextPage = this.post.ID;}
     
     /*
      We trick Framework7 router that there is just one page in history, so it wont reload one before also during transition and cause flickerin effect. Another options have been tested and this looks the only possible solution with framework v5
      */


       let lastPages = this.$f7.views.main.router.history;

       var copy = [...this.$f7.views.main.router.history];

       if(lastPages && lastPages.length > 1)
       {

         let lastPage = lastPages[lastPages.length-2] || "";

         this.$f7.views.main.router.history = [lastPage[-1],lastPage[-2]];

         this.$f7router.back(lastPage, {force:true} )

         setTimeout(()=>{

          copy.pop();
          this.$f7.views.main.router.history = copy;

         },600);

        }
      
    
    },
    /**
     * getItem
     * @description Helper to get wanted data.
     */
    getItem(str,arr)
    {
      if(arr[str]) { return arr[str]; }
      return "";
    },
    /**
     * headerStyles()
     * @description Loads header styles as Framewrok 7 css-variables  - styles comes from Style template
     */
    headerStyles(postId)
    {


      var css = {};
      if(window.lastHeaderStyle) { css = window.lastHeaderStyle; }


       var headerBorders = this.templateStyle["header-borders-group"];




       if(headerBorders)
       {

         css = {
          "--header-color-theme": this.getItem("header-color-theme",this.templateStyle),
          "--header-title-color": this.getItem("header-title-color",this.templateStyle),
          "--header-background": this.getItem("header-background",this.templateStyle),
          "--header-border-top-color": this.getItem("header-border-top-color",headerBorders),
          "--header-border-top-size": this.getItem("header-border-top-size",headerBorders)+"vw",
          "--header-border-bottom-color": this.getItem("header-border-bottom-color",headerBorders),
          "--header-border-bottom-size": this.getItem("header-border-bottom-size",headerBorders)+"vw",
          "--header-border-left-color": this.getItem("header-border-left-color",headerBorders),
          "--header-border-left-size": this.getItem("header-border-left-size",headerBorders)+"vw",
          "--header-border-right-color": this.getItem("header-border-right-color",headerBorders),
          "--header-border-right-size": this.getItem("header-border-right-size",headerBorders)+"vw"
          }

     }

        if(this.$data.header_status == 'off')
       {
         css["opacity"] = "0.0";
       }

        window.lastHeaderStyle = css;

      return css;

    },

  },
  created() {
    

    this.SA_getPost().then(post => {


      store.dispatch("SATemplate/init", {}).then(() => {
   
        this.$data.post = post;

        this.post = post;

        let template = post.acf["sa-page-template"]["post_name"];
        if (template) {
          this.$data.template = template;
        }


        this.$data.headerTitle = post.title;

        this.$data.postId = post.ID;

        /* Tabbar items  */

        var acf = post.acf;


   /* Header items */


          /*  logo */

          let pageLogo = post.acf["post-header-logo"];
          let templateLogo = this.templateStyle["header-logo"];


          let logo = {};

          if(pageLogo)
          {
            logo = pageLogo;
          } else
          {
            logo = templateLogo;
          }


        /* Logo width */

        let postLogoWidth = post.acf["post-header-logo-width"]  || "";
        let templateLogoWidth = this.templateStyle["header-logo-width"]  || "";

        let logo_width = "";

        if(postLogoWidth.length > 0 && postLogoWidth != "0")
        {
          logo_width = postLogoWidth;
        } else
        {
          logo_width = templateLogoWidth;
        }

        const header_status = post.acf["post-header-status"] || "";


        let nav_left = {"type":"","link":"","label":"","img":"","icon":"","back":false};
        const acfLeft = post.acf["post-header-nav-left"];

        if(acfLeft)
        {
          nav_left["type"] = acfLeft["post-header-nav-left-type"]  || "";
          nav_left["link"] = acfLeft["post-header-nav-left-link"] || "";
          nav_left["label"] = acfLeft["post-header-nav-left-label"] || "";
          nav_left["img"] = acfLeft["post-header-nav-left-icon-img"] || "";
          nav_left["icon"] = acfLeft["post-header-nav-left-icon"] || "";

          nav_left["event_name"] = acfLeft["post-header-nav-left-event-name"] || "";
          nav_left["event_value"] = acfLeft["post-header-nav-left-event-value"] || "";


        }


        if(nav_left["type"] == "back") { if(nav_left["label"]) { nav_left["back"] = nav_left["label"] } else { nav_left["back"] = true; } }

        this.$data.headerNavLeft = nav_left;

        let nav_right = {"type":"","link":"","label":"","img":"","icon":"","back":false};
        const acfRight = post.acf["post-header-nav-right"];

        if(acfRight)
        {
          nav_right["type"] = acfRight["post-header-nav-right-type"] || "";
          nav_right["link"] = acfRight["post-header-nav-right-link"] || "";
          nav_right["label"] = acfRight["post-header-nav-right-label"] || "";
          nav_right["img"] = acfRight["post-header-nav-right-icon-img"] || "";
          nav_right["icon"] = acfRight["post-header-nav-right-icon"] || "";


          nav_right["event_name"] = acfRight["post-header-nav-right-event-name"] || "";
          nav_right["event_value"] = acfRight["post-header-nav-right-event-value"] || "";
        }

        if(nav_right["type"] == "back") { if(nav_right["label"]) { nav_right["back"] = nav_right["label"] } else { nav_right["back"] = true; } }

        this.$data.headerNavRight = nav_right;

        if(header_status) {  this.$data.header_status = header_status; }

        if(logo_width) { this.$data.logo_width =  logo_width+"%"; }

        if(logo)
        {
          if(logo.url)
          {
            let wp_url = window.SAPlatform.server.url;
            let url = logo.url;
            url = url.replace(wp_url,"");

            /* Very quickfix for url problem: https://sa-wp-tests-1.fi/https://sa-wp-tests-1.fi/wp-content/uploads/2020/09/chucho.jpg  */

            url = url.split("wp-content/").pop();
            this.$data.logo = wp_url+"/wp-content/"+url;
          }

        }

      });
    });
  }
};
</script>

<style type="css/scss" scoped>

/* Navigation  */

.nav-header
{
/*   background:var(--header-background); */
}

.nav-header >>> .navbar-bg
{
  background:var(--header-background);
}

.nav-header >>> .navbar-inner
{

}

.nav-header >>> .navbar-inner > .title
{
  color:var(--header-title-color);
}

.nav-header >>> .navbar-inner > .left, .nav-header >>> .navbar-inner > .left  > .link
{
   color:var(--header-title-color);
}

.nav-header >>> .navbar-inner > .right, .nav-header >>> .navbar-inner > .right > .link
{
   color:var(--header-title-color);
}

.navbar
{

    border-bottom-color:var(--header-border-bottom-color);
    border-top-color:var(--header-border-top-color);
    border-left-color:var(--header-border-left-color);
    border-right-color:var(--header-border-right-color);

    border-bottom-width:var(--header-border-bottom-size);
    border-top-width:var(--header-border-top-size);
    border-left-width:var(--header-border-left-size);
    border-right-width:var(--header-border-right-size);

    border-bottom-style:solid;
    border-top-style:solid;
    border-left-style:solid;
    border-right-style:solid;


}
.device-ios * .navbar-inner .title 
{

left:-3vw !important;

}

</style>
