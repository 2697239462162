
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersist from 'vuex-persist'

export default {
  namespaced: true,
  state: {items: [],config:{theme:{},empty:true}},
  mutations: {

    setConfig(state, config) {
      state.config = config;
    }

  },
  actions: {

    init(context, item)
    {
      return  new Promise((resolve, reject) => {

          if(!context.state.config.empty) { resolve(true); return 0; }

          axios.get(window.SAPlatform.server.url+"wp-json/template/production/style/data/").then(results => {

          context.commit("setConfig", results.data);

          resolve(true);

        }).catch(()=>
        {
          reject();
        })

      });
    }

  },
  getters: {



    /*   maybe not in use */
   // getColorTheme: (state) => (key,item) => { console.log("get color theme",key,item);  if(item.length > 1) { return state.config["templates"][item][key]; } return state.config.theme["main-color-theme"]; } ,

    /* maybe not in use */
  //  getMainColorTheme: (state) => { console.log("get main color theme");   console.log(state); return state.config.theme["main-color-theme"]; },

    /* [ok]  */
    getHeaderColorTheme: (state) => {  return state.config.theme["main-color-theme"]; },

    /* [ok] */
    getTemplateConfig: (state) => (template) =>
    {
      /* console.log("getTemplateConfig");
      console.log("template:",template); */

      if(template && state.config.templates)
      {
        if(template.length > 1)
        {

          let found = state.config.templates.hasOwnProperty(template);

         // if( found > -1)
         // {

            if(state.config.templates[template]["acf"])
            {

             return  state.config.templates[template]["acf"];

            }
        //  }
        }

      }

      return {};

    },
    getConfig: (state) =>
    {
      //console.log("get config",state.config);
      return state.config;
    }

  }
};
