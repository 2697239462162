<template>
  <f7-page>
    <f7-navbar title="Area 52" back-link="Back" />

  </f7-page>

</template>

<script>

import SuperAppComponentCore from "@sa-platform/sa-p-component-core";
import axios from "axios";

export default {

  extends: SuperAppComponentCore,

  data() {
    return {

    };
  },
  methods:
    {

    },
  mounted() {

  },
};
</script>
