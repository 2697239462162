<template>
  <f7-page>
    <f7-navbar title="Area 51!" back-link="Back" />

  </f7-page>

</template>

<script>

import SuperAppComponentCore from "@sa-platform/sa-p-component-core";
import SAPlatform from "@sa-platform/sa-p-core/core.js";
import axios from "axios";

/**
 * @module Area51
 * @description lorem ipsum dolor... 
 * @vue-prop {String} [message="nothing"] - This is optional prop with defaul value
 * @vue-prop {String} user_meta - This is required prop
 * @vue-prop {Number} test=0 - This is required prop with default value
 * @vue-data {Number} test - This will be the description1
 */
export default {

  extends: SuperAppComponentCore,

  data() {
    return {

    };
  },
  methods:
    {

    },
  mounted() {
    console.log("Area 51 says Hello World!");
  },

};
</script>
