<template>
  <!-- App -->
  <f7-app
    :params="f7params"
    v-bind:class="{ 'theme-dark': theme }"
    :color-theme="color_theme"
  >
    <!-- Left Panel -->
    <f7-panel left cover>
      <f7-view url="/panel-left/">
        <sac-c-pp-custompanelleft />
      </f7-view>
    </f7-panel>

    <!-- Right Panel -->
    <f7-panel right cover>
      <f7-view url="/panel-right/">
        <div class="sa-sidebar-img">
          <img :src="sidebarImg(config)">
        </div>
        <sac-c-pp-custompanelright />
      </f7-view>
    </f7-panel>

    <!-- Main View -->
    <f7-view id="main-view" :push-state="pushState" url="/" main />
  </f7-app>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import {store} from "./store/store.js";
import {mapState} from "vuex";
import {mapGetters} from "vuex";
import axios from "axios";
import SAF7AppCore from "@sa-platform/sa-p-core/framework7-app-core.vue";
import * as util from "@/util";
import * as push from "@sa-platform/sa-p-pushnotification";
import SAGeneralCore from "./components/sac-c-sageneral-components/components/sac-c-sageneral-core/sac-c-sageneral-core.vue";

const SAPlatformStore = window.SAPlatform.stores.SAPlatformStore;

export default {
  mixins: [SAF7AppCore, SAGeneralCore],
  computed: {
    ...mapState({
      user: (state) => state.app.user,
      editedPost: (state) => state.posts.post_edited,
    }),
    colorTheme: {
      get() {
        return "";
      },
    },
    config: {
      get() {
        return this.$store.getters["SATemplate/getConfig"];
      },
    },
    localeTexts() {
      return this.$data.texts[this.$data.lang];
    },
  },
  data() {
    return {
      template1: "<p></p>",
      // Framework7 parameters here
      f7params: {
        // App bundle ID
        id: "com.superapp.sa-mobile-app",
        // App name
        name: "sa-mobile-app",
        view: {
          iosDynamicNavbar: false, // Breaks navbar sometimes during navigation if enabled
          mdSwipeBack: true,
          removeElements: true, // if this is false then header bar titles gets stacked
          stackPages: false, // if this is true then vendor feed tabs are not working (page is created multiple times)
        },
        navbar: {
          mdCenterTitle: true,
        },
        dialog: {
          buttonOk: "OK",
          buttonCancel: window.SAPlatform.language.getCurrent() == "fi" || "" ? "Peruuta" : "Cancel",
          usernamePlaceholder: window.SAPlatform.language.getCurrent() == "fi" || "" ? "Käyttäjänimi" : "Username",
          passwordPlaceholder: window.SAPlatform.language.getCurrent() == "fi" || "" ? "Salasana" : "Password",
        },
      },
      lang: window.SAPlatform.language.getCurrent() || "en",
      texts: {
        en: {
          cancel_btn: "Cancel",
          username_placeholder: "Username",
          password_placeholder: "Password",
          pwd_forgot_prompt_title: "Reset password",
          pwd_forgot_prompt_msg: "Type in email address.",
          pwd_forgot_success_title: "Password reseted",
          pwd_forgot_success_msg: "Check your email. Password should arrive in couple of minutes. Remember to check junk mail too.",
          pwd_forgot_failure_title: "Reset not possible!",
          pwd_forgot_failure_msg: "Check you've typed in correct email address: ",
          activation_alert_title: "Account not activated!",
          activation_alert_msg: "Check your email for activation link.",
          discard_post_confirm_title: "Warning",
          discard_post_confirm_msg: "You have unsaved changes. Are you sure you want to leave this page? Changes will be lost.",
          already_registered_title: "Account",
          already_registered_msg: "Your account is already registered",
        },
        fi: {
          cancel_btn: "Peruuta",
          username_placeholder: "Käyttäjänimi",
          password_placeholder: "Salasana",
          pwd_forgot_prompt_title: "Salasanan palautus",
          pwd_forgot_prompt_msg: "Kirjoita sähköpostiosoitteesi",
          pwd_forgot_success_title: "Salasana palautettu",
          pwd_forgot_success_msg: "Tarkkaile sähköpostiasi! Salasana lähetetään sinulle hetken kuluttua. Muista tarkistaa myös roskapostit!",
          pwd_forgot_failure_title: "Palautus ei onnistunut!",
          pwd_forgot_failure_msg: "Tarkista, että antamasi sähköpostiosoite on oikein: ",
          activation_alert_title: "Tiliä ei ole aktivoitu!",
          activation_alert_msg: "Tarkista aktivointilinkki sähköpostistasi.",
          discard_post_confirm_title: "Varoitus",
          discard_post_confirm_msg: "Sinulla on tallentamattomia muutoksia. Haluatko varmasti poistua tältä sivulta? Muutokset menetetään.",
          already_registered_title: "Tili",
          already_registered_msg: "Sinulla on jo tili olemassa",
        },
      },
    };
  },
  methods: {
    async checkPushPermission() {
      const hasPermission = await push.hasPermission();
      if (hasPermission) return true;

      const granted = await push.requestPermission();
      return granted;
    },
    async initPushNotifications() {
      const self = this;

      await this.checkPushPermission();

      push.addMessageHandler(async message => {
        console.log("push notification message", message);

        if (message.tap == "background" && message.type == "post") {
          await this.$store.dispatch("app/getVendorProfile", message.vendorId);

          const link = this.createLinkWithPayload("/vendor-profile-2/", {vendor_id: message.vendorId, content_id: message.contentId});
          this.$f7.view.current.router.navigate(link);
        }
      });
    },
    sidebarImg(config) {
      return config && config.data && config.data["main-sidebar-img"] || "";
    },
    backButtonInit() {
      const self = this;
      document.addEventListener(
        "deviceready",
        () => {
          document.addEventListener("backbutton", onBackKeyDown, false);

          function onBackKeyDown(event) {
            event.preventDefault();
            event.stopPropagation();

            const app = self.$f7;
            const leftp = app.panel.left && app.panel.left.opened;
            const rightp = app.panel.right && app.panel.right.opened;

            if (leftp || rightp) {
              app.panel.close();
              return false;
            } else if (self.$$(".modal-in").length > 0) {
              app.dialog.close();
              app.popup.close();
              app.popover.close();

              return false;
            } else if (app.views.main.router.history.length === 1) {
              navigator.app.exitApp();
            } else {
              app.views.main.router.back();
              return false;
            }
          }
        },
        false
      );
    },
    async getFilterItems() {
      await this.$store.commit("initialiseStore");
      this.$store.dispatch("app/getFilterItems");
    },
    checkPhoneVerified() {
      const user = window.SAPlatform.user.current();
      const phoneChanged = user.get("phone_changed");
      const phoneVerified = user.get("phone_verified");

      if (phoneChanged == "true" || phoneVerified != "true") {
        const userId = user.data.ID;
        const phone = user.get("phone");

        axios.post(`${window.SAPlatform.server.url}wp-json/superapp/v1/verification-code/send`, {
          user_id: userId,
          phone,
        });
        this.$f7.view.current.router.navigate("/verification/", {});
        return false;
      }
      return true;
    },
    updateFeed() {
      const event = new CustomEvent("update-feed");
      window.dispatchEvent(event);
    },
  },
  async mounted() {
    if ("cordova" in window) {
      this.initPushNotifications();
    }

    this.getFilterItems();

    this.$f7ready(f7 => {
      let platformName = "";
      if (f7.device.ios) platformName = "ios";
      else if (f7.device.android) platformName = "android";
      else if (f7.device.desktop) platformName = "desktop";
      this.$store.commit("app/setPlatform", platformName);
      console.info(`Platform recognized as ${platformName}`);
    });

    /** Session event listeners (needed)  */

    // Listening log in
    this.listen("user-session-created", (data) => {
      console.log("user-session-created");

      // Removing history so android backbutton works correctly
      this.$f7.views.main.router.history = [];

      setTimeout(() => {
        const user = window.SAPlatform.user.current();
        const userLang = user.get("locale");
        if (userLang) {
          this.$data.lang = userLang;
          window.SAPlatform.language.setCurrent(userLang);
        }
      }, 250);

      setTimeout(async () => {
        await this.$store.dispatch("app/getUser");

        if (this.user.hasOwnProperty("vendor_id") && this.user.vendor_id) {
          await this.$store.dispatch("app/getOwnVendorProfile", this.user.vendor_id);
        } else {
          let position = null;
          await util.getGeolocation().then((pos) => {
            position = {lat: pos.coords.latitude, lng: pos.coords.longitude};
          })
            .catch((err) => {
              position = null;
            });
          await this.$store.dispatch("activity/getUserActivity", position);
        }
      }, 1000);
    });

    this.listen("user-session-init", async (data) => {
      console.log("user session init");

      if ("cordova" in window) {
        push.registerDevice();
      }
    });

    // Password forgot window
    this.listen("forget-pw", (data) => {
      this.$f7.dialog.create({
        title: this.localeTexts.pwd_forgot_prompt_title,
        text: this.localeTexts.pwd_forgot_prompt_msg,
        content: `
          <div class="dialog-input-field input">
            <input class="dialog-input" type="text" name="email" />
          </div>
        `,
        buttons: [
          {
            text: this.localeTexts.cancel_btn,
            onClick: () => {},
          },
          {
            text: "OK",
            cssClass: "dialog-button-bold",
            onClick: () => {
              const email = this.$$(".dialog-input-field input[name=\"email\"]").val();
              window.SAPlatform.user
                .requestPasswordReset(email)
                .then((response) => {
                  this.$f7.dialog.alert(
                    this.localeTexts.pwd_forgot_success_msg,
                    this.localeTexts.pwd_forgot_success_title
                  );
                })
                .catch((error) => {
                  this.$f7.dialog.alert(
                    this.localeTexts.pwd_forgot_failure_msg + email,
                    this.localeTexts.pwd_forgot_failure_title
                  );
                });
            },
          },
        ],
      }).open();
    });

    // Listening log out
    this.listen("logout-user", async (data) => {
      if ("cordova" in window) {
        await push.removeDevice();
      }

      console.log("Logging out");
      window.SAPlatform.user.LogOut();

      // Removing history so android backbutton works correctly
      this.$f7.views.main.router.history = [];
      this.$f7.view.current.router.navigate("/", {});

      this.$store.dispatch("resetAll");
    });

    this.listen("login-user", (data) => {
      console.log("Logging in");
      const user = window.SAPlatform.user.current();
      let link;
      const userRole = user.data.roles[0];
      if (userRole == "appuser") {
        const newUser = user.get("new_user");
        const accountActivated = user.get("account_activated");
        if (newUser == "no" && accountActivated == 1) {
          link = "/";
          this.$f7.views.main.router.history = [];
        } else if (newUser == "yes" && accountActivated == 1) {
          link = "/";
          this.$f7.views.main.router.history = [];
          user.set("new_user", "no");
          user.save();
        } else {
          // Link = "/register/thank-you-for-signing/";
          link = "/";
        }

        this.$f7.views.main.router.history = [];
        this.$f7.view.current.router.navigate(link, {animate: true, transition: "f7-fade"});
      } else if (userRole == "vendoruser") {
        const verified = this.checkPhoneVerified();
        if (verified) {
          link = "/vendor-home/";

          this.$f7.view.current.router.navigate(link, {animate: true, transition: "f7-fade"});
          this.$f7.views.main.router.history = [];
        }
      }
    });

    // Listening some login
    this.listen("social-login", (data) => {
      const self = this;
      const metaChoises = this.$$(".user-meta-choices");

      let typeValue, genderValue, ageValue;

      if (metaChoises) {
        const typeChoises = metaChoises[0];
        const genderChoises = metaChoises[1];
        const ageChoises = metaChoises[2];

        if (typeChoises) {
          const selectedType = this.$$(typeChoises).find(".selected");

          if (selectedType.length > 0) {
            const typeParts = selectedType[0].getAttribute("id").split("-");
            typeValue = typeParts[typeParts.length - 1].toLowerCase();
          }
        }

        if (genderChoises) {
          const selectedGender = this.$$(genderChoises).find(".selected");
          if (selectedGender.length > 0) {
            const genderParts = selectedGender[0].getAttribute("id").split("-");
            genderValue = genderParts[genderParts.length - 1].toLowerCase();
          }
        }

        if (ageChoises) {
          const selectedAge = this.$$(ageChoises).find(".selected");
          if (selectedAge.length > 0) {
            const ageParts = selectedAge[0].getAttribute("id").split("-");
            ageValue = ageParts[ageParts.length - 1].toLowerCase();
          }
        }
      }

      window.SAPlatform.user
        .socialLogIn(data.detail.value, {target: "safariviewcontroller"})
        .then(async (response) => {
          const user = window.SAPlatform.user.current();
          const email = user.get("user_email");
          console.log(user);
          console.log(email);

          const registerForm = this.$$(".page-current").find(".register-form");

          if (response.detail.signup == "false" && registerForm.length > 0) {
            self.$f7.dialog.alert(
              self.localeTexts.already_registered_msg,
              self.localeTexts.already_registered_title
            );
            return;
          }

          // Save meta data to user if we are on register page and meta is selected
          if (typeValue || genderValue || ageValue) {
            await this.$store.dispatch("app/saveUserMeta", [typeValue, genderValue, ageValue]);
          }

          this.$f7.views.main.router.history = [];
          this.$f7.view.current.router.navigate("/", {animate: true, transition: "f7-fade"});
        });
    });

    // Listening language change
    this.listen("change-language", (data) => {
      if (data.detail && data.detail.value) {
        console.log("Changing language:");
        window.SAPlatform.language.setCurrent(data.detail.value);
      }
    });

    this.listen("current-language-changed", () => {
      this.$data.lang = window.SAPlatform.language.getCurrent();

      this.getFilterItems();

      this.$data.f7params.dialog.buttonCancel = this.localeTexts.cancel_btn;
      this.$data.f7params.dialog.usernamePlaceholder = this.localeTexts.username_placeholder;
      this.$data.f7params.dialog.passwordPlaceholder = this.localeTexts.password_placeholder;
    });

    this.listen("check-activation", data => {
      window.SAPlatform.user.current().save()
        .then(() => {
          const user = window.SAPlatform.user.current();
          const newUser = user.get("new_user");
          const accountActivated = parseInt(user.get("account_activated"));

          if (newUser == "yes" && accountActivated == 0) {
            this.$f7.dialog.alert(
              this.localeTexts.activation_alert_msg,
              this.localeTexts.activation_alert_title
            );
          } else {
            user.set("new_user", "no");
            user.save();
            this.$f7.views.main.router.history = [];
            this.$f7.view.current.router.navigate("/");
          }
        });
    });

    // Listening form response
    this.listen("sa-form-got-response", async event => {
      const formData = event.detail.data;
      if (formData.form == "registeruser" && formData.data.code == "USER_CREATED") {
        const username = formData.items.username.value;
        const pw = formData.items.pw1.value;

        await window.SAPlatform.user.logIn(username, pw);
        this.$f7.view.current.router.navigate("/register/thank-you-for-signing/");
      } else if (formData.form == "userinfo" && formData.data.code == "USER_UPDATED") {
        SAPlatformStore.dispatch("form/clear", "userinfo");
        const user = window.SAPlatform.user.current();
        user.save();
        await this.$store.dispatch("app/getUser");
      } else if (formData.form == "claimbusiness" && formData.data.code == "VENDOR_CLAIMED") {
        this.$f7.preloader.show();
        const user = window.SAPlatform.user.current();
        if (user) {
          const arr = [];
          arr.detail = {value: "1"};
          window.dispatchEvent(new CustomEvent("logout-user", arr));
        }

        const userName = formData.data.username;
        const password = formData.data.password;
        window.SAPlatform.user.logIn(userName, password).then((response) => {
          this.$f7.preloader.hide();
          this.$f7.view.current.router.navigate("/verification/");
          this.$f7.views.main.router.history = [];
        });
      }
    });

    // Listening update vendor profile
    this.listen("update-vendor", (data) => {
      this.$store.dispatch("app/getUser");
      this.$store.dispatch("app/getOwnVendorProfile", this.user.vendor_id);
      if (data.detail == "true") {
        this.$f7.views.main.router.back();
      }
    });

    // Listening update vendor user
    this.listen("update-vendor-user", (data) => {
      const user = window.SAPlatform.user.current();
      const phoneChanged = user.get("phone_changed");
      if (phoneChanged == "true") {
        user.set("phone_verified", "false");
        user.save();
        const userId = user.data.ID;
        const phone = user.get("phone");
        axios.post(`${window.SAPlatform.server.url}wp-json/superapp/v1/verification-code/send`, {
          user_id: userId,
          phone,
        });
        this.$f7.view.current.router.navigate("/verification/", {});
      } else {
        this.$f7.view.current.router.navigate("/vendor-home/", {});
      }
    });

    // Listening sainput update
    this.listen("sainput-update", (data) => {
      if (data.detail.item.form == "vendorinfo" && data.detail.item.name == "phone") {
        const newPhone = data.detail.item.value;
        const user = window.SAPlatform.user.current();
        const oldPhone = user.get("phone");
        if (newPhone != oldPhone) {
          user.set("phone_changed", "true");
          user.save();
        } else {
          user.set("phone_changed", "false");
          user.save();
        }
      }
    });

    // Listening clear phone change
    this.listen("clear-phonechange", (data) => {
      const user = window.SAPlatform.user.current();
      user.set("phone_changed", "false");
      user.save();
    });

    /*
     * Listening navigate user (when user clicks back button on vendor profile)
     * NOT IN USE anymore, caused a lot of problems
     */
    this.listen("navigate-user", (data) => {
      const user = window.SAPlatform.user.current();
      let link = "";
      const prevRoute = this.$f7.views.main.router.previousRoute;

      if (user) {
        const userRole = user.data.roles[0];

        if (userRole == "vendoruser") {
          if (prevRoute && prevRoute.path) {
            if (prevRoute.path == "/vendor-home/" || prevRoute.path == "/13866/" || prevRoute.path == "/48614/") link = "/vendor-home/";
            else link = "/main-feed-vendor/";
          } else link = "/main-feed-vendor/";
          this.$store.dispatch("app/getOwnVendorProfile", this.user.vendor_id);
        } else if (prevRoute && prevRoute.path) {
          if (prevRoute.path == "/user-profile/") link = prevRoute.path;
          else if (prevRoute.path.includes("/user-public-profile/")) link = prevRoute.path;
          else link = "/";
        } else link = "/";

        this.$f7.view.current.router.back(link, {force: true});
      } else {
        if (prevRoute && prevRoute.path) {
          if (prevRoute.path.includes("/user-public-profile/")) link = prevRoute.path;
          else link = "/";
        } else link = "/";

        this.$f7.view.current.router.back(link, {force: true});
      }
    });

    this.listen("route-page", (data) => {
      if (data.detail && data.detail.value) {
        this.$f7.views.main.router.navigate(data.detail.value);
      } else if (data.detail) {
        this.$f7.views.main.router.navigate(data.detail);
      }
    });

    this.listen("check-modified-post", (data) => {
      if (this.editedPost === true) {
        this.$f7.dialog.create({
          title: this.localeTexts.discard_post_confirm_title,
          text: this.localeTexts.discard_post_confirm_msg,
          buttons: [
            {
              text: this.localeTexts.cancel_btn,
              onClick: () => {},
            },
            {
              text: "OK",
              cssClass: "dialog-button-bold",
              onClick: () => {
                this.$store.dispatch("posts/resetCurrentPost");
                this.$f7.view.current.router.navigate("/post/", {});
              },
            },
          ],
        }).open();
      } else {
        this.$store.dispatch("posts/resetCurrentPost");
        this.$f7.view.current.router.navigate("/post/", {});
      }
    });

    // Internet connection alerts */
    document.addEventListener("offline", (e) => {
      dialog = this.$f7.dialog.create({
        title: "No internet connection",
        text: "This app needs an internet connection to work.",
        buttons: [
          {
            text: "OK",
          },
        ],
      });

      dialog.open();
    });

    /*  Handling WKWebView issues with IOS here */
    if (window.WkWebView) {
      // Fixes keyboard issues if user does back swipe
      this.$f7.view.current.router.app.on("pageAfterIn", (page) => {
        window.scrollBy({top: 0, left: 0, behaviour: "smooth"});
      });

      // Handles keyboard issues with basic focusout events by scrolling up
      document.addEventListener("focusout", () => {
        window.scrollBy({top: 0, left: 0, behaviour: "smooth"});
      });
    }

    // Android backbutton init
    this.backButtonInit();

    /** Hardcoded login redirects (temporary solution)  */
    if (window.cordova || (window.location.pathname == "/" || window.location.pathname == "/dev/") && window.location.hash.length <= 3) {
      const user = window.SAPlatform.user.current();
      if (!user) {
        console.log("user not logged in. redirecting to front page");
        this.$f7.views.main.router.history = [];
        return;
      }

      console.log("logged in", user);
      const userLang = user.get("locale");
      this.$data.lang = userLang;
      window.SAPlatform.language.setCurrent(userLang);
      await util.wait(150);

      let link;
      const userRole = user.data.roles[0];
      if (userRole == "appuser") {
        const newUser = user.get("new_user");
        const accountActivated = user.get("account_activated");
        if (newUser == "no" && accountActivated == 1) {
          link = "/";
          //this.$f7.views.main.router.history = [];
        } else if (newUser == "yes" && accountActivated == 1) {
          link = "/";
          //this.$f7.views.main.router.history = [];
          user.set("new_user", "no");
          user.save();
        } else {
          link = "/";
        }

        //this.$f7.views.main.router.history = [];
        this.$f7.view.current.router.navigate(link, {animate: true, transition: "f7-fade"});
      } else if (userRole == "vendoruser") {
        const verified = this.checkPhoneVerified();
        if (verified) {
          link = "/vendor-home/";

          this.$f7.view.current.router.navigate(link, {animate: true, transition: "f7-fade"});
          this.$f7.views.main.router.history = [];
        }
      }
    }
  },
};
</script>
