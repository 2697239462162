import SAPlatform from "@sa-platform/sa-p-core";

function getDeviceToken() {
    if(!isAvailable()) throw Error("FirebasePlugin is not available");

    return new Promise((resolve, reject) => {
        window.FirebasePlugin.getToken(
            token => resolve(token), 
            err => reject(err)
        );
    });
}

function isAvailable() {
    return "FirebasePlugin" in window;
}

/**
 * @return {Promise<boolean>}
 */
async function registerDevice() {
    const token = await getDeviceToken();
    const resp = await SAPlatform.platform.api.post("/device/register", {token});
    
    return resp.status === 201 || resp.status === 200;
}

/**
 * @return {Promise<boolean>}
 */
async function removeDevice() {
    const token = await getDeviceToken();
    const resp = await SAPlatform.platform.api.post("/device/remove", {token});

    return resp.status === 200;
}

function subscribeTopic(topic) {
    window.FirebasePlugin.subscribe(topic);
}

function unsubscribeTopic(topic) {
    window.FirebasePlugin.unsubscribe(topic);
}

function clearAllNotifications() {
    window.FirebasePlugin.clearAllNotifications();
}

/**
 * @return {Promise<boolean>}
 */
function requestPermission() {
    return new Promise(resolve => {
        window.FirebasePlugin.grantPermission(result => resolve(result));
    });
}

/**
 * @return {Promise<boolean>}
 */
function hasPermission() {
    return new Promise(resolve => {
        window.FirebasePlugin.hasPermission(granted => resolve(granted));
    });
}

function addMessageHandler(handler) {
    window.FirebasePlugin.onMessageReceived(handler);
}

export {
    isAvailable,
    registerDevice,
    removeDevice,
    subscribeTopic,
    unsubscribeTopic,
    clearAllNotifications,
    requestPermission,
    hasPermission,
    addMessageHandler
};
